.donations-bg {
  position: relative;
  height: 100%;
  z-index: 0;
}

.donations-bg::before {
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 750px;
  background-image: url("../assets/bg_links.png");
  /* background-attachment: fixed; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  opacity: 0.4;
  z-index: -1;
}

.donations-header {
  position: relative;
  color: #c1911a;
  font-size: 3rem;
  margin: auto;
  width: 90%;
  padding: 10px 0;
}

.donations-container {
  margin: 5px auto 0px auto;
  width: 100%;
  border-top: #c1911a 3px solid;
  /* padding: 20px 65px; */
  display: flex;
  flex-direction: column;
}

.donations-text {
  font-size: 2rem;
  text-align: justify;
  margin-top: 1rem;
  margin-bottom: 55px;
  padding: 0px 65px;
  color: #c1911a;
}

.donations-button {
  display: inline-block;
  margin-top: 5rem;
  margin-bottom: 1.5rem;
  padding: 15px 50px;
  font-size: 3rem;
  cursor: pointer;
  background-color: goldenrod;
  color: white;
  border: none;
  border-radius: 4rem;
}

.donations-button:hover {
  background-color: #c1911a;
}

.donations-followup {
  position: relative; /* Ensure the container is positioned relative for the pseudo-element */
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding: 50px 0px;
  height: 60vh;
  background-image: url("../assets/donate-image.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: #ececec;
  z-index: 1; /* Ensure the text is above the overlay */
}

.donations-followup::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(49, 49, 49, 0.7); /* Dark transparent overlay */
  z-index: -1; /* Place the overlay behind the content */
}

.don-fol-text {
  padding: 0px 65px;
}

.don-text-img {
  padding: 0px 65px;
  width: 100%;
  height: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.donations-img1 {
  width: 650px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.donations-text-right {
  padding: 0px 40px;
  width: 50%;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 15px;
  color: #c1911a;
}

.donations-wide-img {
  width: 100%;
  height: auto;
  /* border-radius: 8px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  /* margin-bottom: 5rem; */
  /* padding: 0px 65px; */
}

.donations-pay-types-img {
  width: 300px;
  height: auto;
  margin-bottom: 2rem;
}

.donations-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 960px) {
  .don-text-img {
    padding: 0px;
    width: 100%;
    height: auto;
    flex-direction: column;
    margin-top: 2rem;
  }

  .donations-img1 {
    width: 80%;
  }

  .donations-text-right {
    padding: 0px 15px;
    width: 90%;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 5rem;
    color: #c1911a;
  }

  .donations-wide-img {
    display: none;
  }
}
@media (max-width: 768px) {
  .donations-bg::before {
    background-size: cover;
    opacity: 0.3;
  }

  .donations-container {
    width: 100%;
    /* padding: 10px 20px; */
  }

  .donations-button {
    display: inline-block;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 15px 50px;
    font-size: 2rem;
    border-radius: 4rem;
  }

  .donations-followup {
    position: relative; /* Ensure the container is positioned relative for the pseudo-element */
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .don-text-img {
    padding: 0px;
    width: 100%;
    height: auto;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 0;
  }

  .donations-img1 {
    width: 100%;
    border-radius: 0px;
  }

  .donations-text-right {
    padding: 0px 15px;
    width: 100%;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 5rem;
    color: #c1911a;
  }

  .donations-wide-img {
    display: none;
  }

  .donations-text {
    font-size: 1.5rem;
    text-align: left;
    padding: 0px 20px;
  }

  .donations-pay-types-img {
    width: 300px;
    height: auto;
    margin-bottom: 4rem;
  }
}
