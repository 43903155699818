.nav-bar {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  /* Mobile view */
  .nav-bar {
    padding-top: 10px;
    padding-bottom: 15px;
  }
}

.nav-bar a {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  transition: color 0.2s ease;
}

.nav-bar a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: goldenrod;
  transition: width 0.4s ease, right 0.4s ease;
}

.nav-bar a:not(.logo-link):hover::after,
.nav-bar .active-link::after {
  width: 100%;
  right: 0;
}

.nav-bar a:not(.logo-link):hover {
  color: goldenrod;
}

.active-link {
  color: goldenrod;
}

.other-link {
  color: #2d2d2d;
}

.nav-contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  gap: 10px;
  padding: 0;
}

.nav-icon-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.nav-drawer-icon {
  color: goldenrod;
  font-size: 2.5rem;
}

.nav-drawer-icon:hover {
  color: #e5e5e5;
}

.nav-contact-text {
  width: 100%;
  font-size: 1rem;
  text-decoration: none;
  color: #2d2d2d;
}

.nav-contact-text:hover {
  color: goldenrod;
}
