.gangaramaya-page {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: fit-content;
  min-height: 95vh;
}

.gangaramaya-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  padding: 20px 60px 20px 60px;
  width: 80%;
}

.lang-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;
  padding: 0 20px;
  position: fixed;
  bottom: 2rem;
  right: 0;
  margin: 0 2rem;
}

.language-button {
  background-color: #f8f8f8;
  cursor: pointer;
}

.language-option {
  background-color: #f8f8f8;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
}

.gangaramaya-back-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.gangaramaya-back-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.gangaramaya-content-header {
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .gangaramaya-content {
    width: 100%;
    padding: 20px 20px 20px 20px;
  }
}
