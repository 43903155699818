/* Blog.css */
.blog-bg {
  position: relative;
  height: 100%;
  z-index: 0;
}

.blog-header {
  color: #c1911a;
}

.blog-bg::before {
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 750px;
  background-image: url("../assets/bg_links.png");
  /* background-attachment: fixed; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  opacity: 0.2;
  z-index: -1;
}

.load-more-button {
  background-color: #dbbb69;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
}
@media (max-width: 768px) {
  .blog-bg::before {
    background-size: cover;
  }
}
