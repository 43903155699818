.meditation-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 60px;
}
.med-text-container {
  flex: 1;
  width: 60%;
  margin-left: 30px;
  margin-right: 70px;
  padding: 60px 30px;
  text-align: justify;
}
.meditation-paragraph {
  font-size: 1.2rem;
  padding: 10px;
  text-align: justify;
  margin-bottom: 15px;
  color: rgb(129, 97, 18);
}

.meditation-img-container {
  flex: 1;
  width: 40%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 20px;
}
.meditation-image {
  width: 60%;
  /* max-width: 500px; */
  height: auto;
  opacity: 0.75;
  transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out;
  border: 4px solid goldenrod;
}

.meditation-image:hover {
  opacity: 1;
  /* width: 61%; */
}

@media (max-width: 1024px) {
  .med-text-container {
    width: 70%;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px 20px;
  }
  .meditation-paragraph {
    font-size: 1.2rem;
    padding: 10px;
    text-align: justify;
    margin-bottom: 15px;
    color: rgb(129, 97, 18);
  }
  .meditation-img-container {
    width: 30%;
    margin-top: 0px;
    margin-left: 0px;
  }
  .meditation-image {
    width: 90%;
    /* max-width: 500px; */
    height: auto;
    opacity: 0.75;
    transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out;
    border: 4px solid goldenrod;
  }
}

@media (max-width: 768px) {
  .meditation-container {
    flex-direction: column;
    margin-top: 20px;
  }

  .med-text-container {
    width: 100%;
    text-align: justify;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    padding: 50px 10px;
  }
  .meditation-paragraph {
    font-size: 1.2rem;
    /* padding: 20px; */
    text-align: justify;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
  .meditation-img-container {
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
  }
  .meditation-image {
    width: 80%;
    max-width: 400px;
    opacity: 0.9;
  }
}
