@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutToTop {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-1500px);
  }
}

@keyframes popupFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popupFadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-700px);
  }
}

.nm-scroll-animation {
  padding: 20px;
  margin: 24px 0;
  border: 1px solid goldenrod;
  border-radius: 1rem;
  background-color: #f9f9f9;
  animation: fadeInFromTop 1s ease-out forwards;
  width: 50%;
}

.nm-scroll-animation.closing {
  animation: fadeOutToTop 1.5s ease-out forwards;
}

.nm-note-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 20px 60px 20px;
}

.nm-button-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.nm-note-button {
  display: inline-block;
  padding: 20px 40px;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: goldenrod;
  color: white;
  border: none;
  border-radius: 3rem;
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition */
  position: relative;
}

.nm-note-button:hover,
.nm-note-button.active {
  background-color: #f3b600;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Shadow effect */
}

.nm-note-button.active::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  height: 2px;
  background-color: goldenrod;
}

.nm-note-paragraph {
  font-size: 1.2rem;
  padding: 10px;
  text-align: justify;
  margin-bottom: 15px;
  color: rgb(129, 97, 18);
}

.nm-note-paragraph a {
  color: goldenrod;
  text-decoration: none;
}

.nm-med-paragraph {
  font-size: 1.2rem;
  padding: 10px;
  text-align: justify;
  margin-bottom: 15px;
  color: rgb(129, 97, 18);
}

.nm-note-close-button {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: goldenrod;
  color: white;
  border: none;
  border-radius: 3rem;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: rgb(255, 253, 249);
  padding: 0px 20px 20px 20px;
  /* border-radius: 10px; */
  position: relative;
  max-width: 750px;
  width: 90%;
  max-height: 70%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  border: 8px double goldenrod;
}

.popup-opening {
  animation: popupFadeIn 0.5s ease-out forwards;
}

.popup-closing {
  animation: popupFadeOut 0.5s ease-out forwards;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 3rem;
  cursor: pointer;
}

.popup-title {
  font-size: 2rem;
  color: goldenrod;
  margin-bottom: 20px;
  text-align: center;
}

.popup-content {
  font-size: 1.2rem;
  color: rgb(129, 97, 18);
}

.popup-content a {
  color: goldenrod;
  text-decoration: none;
}
@media screen and (max-width: 1024px) {
  .nm-scroll-animation {
    width: 75%;
  }

  .nm-note-container {
    margin: 0px 20px 70px 20px;
  }

  .nm-button-container {
    gap: 15px;
    margin-top: 40px;
  }

  .nm-note-button.active::after {
    bottom: -12px;
    width: 80%;
  }

  .nm-note-button {
    padding: 15px 15px;
    font-size: 1.5rem;
  }

  .nm-note-close-button {
    padding: 10px 15px;
    font-size: 14px;
  }

  .nm-note-paragraph {
    font-size: 1.1rem;
    padding: 0px;
    margin-bottom: 10px;
  }

  .popup {
    max-width: 600px;
    padding: 0px 15px 25px 15px;
    border: 5px double goldenrod;
  }

  .popup-content {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .nm-scroll-animation {
    margin-top: 24px;
    width: 100%;
  }

  .nm-note-container {
    margin: 0px 20px 70px 20px;
  }

  .nm-button-container {
    gap: 15px;
    margin-top: 40px;
  }

  .nm-note-button.active::after {
    bottom: -12px;
    width: 80%;
  }

  .nm-note-button {
    padding: 15px 15px;
    font-size: 1.1rem;
  }

  .nm-note-close-button {
    padding: 10px 15px;
    font-size: 14px;
  }

  .nm-note-paragraph {
    font-size: 1.1rem;
    padding: 0px;
    margin-bottom: 10px;
  }

  .popup {
    max-width: 500px;
    padding: 0px 15px 15px 15px;
    border: 5px double goldenrod;
  }

  .popup-content {
    font-size: 1rem;
  }
}
