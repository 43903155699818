/* src/Splash.css */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fcfbed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.loading.fade-out {
  opacity: 0;
}

.loading-logo {
  width: 150px; /* Adjust size as needed */
  margin-top: 20px;
}

.spinner {
  width: 65px;
  height: 65px;
  /* border: 5px solid rgba(0, 0, 0, 0.1); */
  /* border-top: 5px solid rgba(40, 44, 52, 0.8); */
  /* border-radius: 50%; */
  animation: spin 6s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
