.pop-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  /* height: 100vh; */
  padding: 0px 30px 30px 30px;
}

.pop-heading {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: rgb(175, 130, 16);
}

@media screen and (max-width: 768px) {
  .pop-container {
    padding: 0px 20px 20px 20px;
    text-align: justify;
  }

  .pop-heading {
    font-size: 1.8rem;
  }
}
