.quote {
  padding: 90px 20px 40px 20px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
  color: goldenrod;
  font-size: 1.8rem;
}

@media screen and (max-width: 768px) {
  .quote {
    padding: 40px 20px 20px 20px;
    font-size: 1.3rem;
    margin-top: 20px;
    margin-bottom: 25px;
  }
}
