@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutToTop {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.point-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 10px;
}

.point-scroll-animation {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid goldenrod;
  border-radius: 1rem;
  background-color: #f9f9f9;
  animation: fadeInFromTop 1s ease-out forwards;
}

.point-scroll-animation.closing {
  animation: fadeOutToTop 1.5s ease-out forwards;
}

.point-note-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: rgb(226, 195, 117);
  color: rgb(18, 18, 18);
  border: none;
  border-radius: 3rem;
  width: 30%;
}

.point-note-button:hover {
  background-color: goldenrod;
}
.point-note-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: rgb(129, 97, 18);
  text-align: center;
}
.point-note-paragraph {
  font-size: 1.2rem;
  /* padding: 10px; */
  text-align: justify;
  margin-bottom: 15px;
  color: rgb(129, 97, 18);
}

.point-note-close-button {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: goldenrod;
  color: white;
  border: none;
  border-radius: 3rem;
}

@media (max-width: 1024px) {
  .point-note-button {
    padding: 10px 15px;
    font-size: 1.3rem;
    width: 60%;
  }
}
@media (max-width: 768px) {
  .point-container {
    margin-bottom: 10px;
  }
  .point-scroll-animation {
    margin-top: 40px;
    width: 100%;
  }
  .point-note-button {
    padding: 10px 10px;
    font-size: 1.1rem;
    width: 100%;
  }
  .point-note-close-button {
    padding: 10px 15px;
    font-size: 14px;
  }
  .point-note-paragraph {
    font-size: 1.1rem;
    padding: 0px;
    margin-bottom: 10px;
  }
}
