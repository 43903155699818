.about-container {
  display: flex;
  align-items: center;
  padding: 20px;
}

.about-logo-container {
  width: 25%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-left: 50px;
}

.about-logo {
  width: 60%;
  height: auto;
  border-radius: 5rem;
}

.about-text {
  width: 75%;
  margin-left: 100px;
  margin-right: 50px;
  padding: 60px 30px;
  text-align: justify;
  font-size: 1.2rem;
  border: 4px solid goldenrod;
  color: rgb(154, 116, 21);
}

@media screen and (max-width: 1024px) {
  .about-container {
    flex-direction: column;
  }
  .about-logo-container {
    width: 60%;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .about-text {
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .about-container {
    flex-direction: column;
  }

  .about-logo-container {
    width: 100%;
    margin-left: 0px;
  }

  .about-text {
    width: 100%;
    text-align: justify;
    margin-left: 0;
    margin-right: 0;
    margin-top: 40px;
    padding: 50px 30px;
  }
}
