@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutToTop {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(1500px);
  }
}

.scroll-animation {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid goldenrod;
  border-radius: 1rem;
  background-color: #f9f9f9;
  animation: fadeInFromTop 1s ease-out forwards;
}

.scroll-animation.closing {
  animation: fadeOutToTop 1.5s ease-out forwards;
}

.note-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 20px 60px 20px;
}
.note-button {
  display: inline-block;
  padding: 20px 60px;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: goldenrod;
  color: white;
  border: none;
  border-radius: 3rem;
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition */
}

.note-button:hover {
  background-color: #f3b600;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Shadow effect */
}

.note-paragraph {
  font-size: 1.2rem;
  padding: 10px;
  text-align: justify;
  margin-bottom: 15px;
  color: rgb(129, 97, 18);
}

.note-close-button {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: goldenrod;
  color: white;
  border: none;
  border-radius: 3rem;
}

@media (max-width: 768px) {
  .scroll-animation {
    margin-top: 40px;
  }
  .note-container {
    margin: 0px 20px 70px 20px;
  }
  .note-button {
    padding: 15px 15px;
    font-size: 1.3rem;
  }
  .note-close-button {
    padding: 10px 15px;
    font-size: 14px;
  }

  .note-paragraph {
    font-size: 1.1rem;
    padding: 0px;
    margin-bottom: 10px;
  }
}
