.gan-footer {
  background-color: #212529; /* Dark background for dark mode */
  color: #f8f9fa; /* Light text color */
  text-align: center;
  padding: 0.5rem;
  font-size: 0.9rem; /* Slightly smaller font size */
}

.gan-text {
  margin: 0.5rem 0;
  line-height: 1.6;
  font-size: 1.2rem;
}

.gan-text2 {
  margin: 0.5rem 0;
  line-height: 1.6;
  font-size: 0.8rem;
}

.gan-link {
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  color: #f8f9fa;
}

.gan-link:hover {
  color: goldenrod; /* Darker blue on hover */
}
