.gangaramaya-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: #f8f8f8;
  height: fit-content;
  min-height: 65;
}

.gangaramaya-header-logo {
  height: 3.3rem;
  cursor: pointer;
}
