.links-bg {
  position: relative;
  height: 100%;
  z-index: 0;
}

.links-bg::before {
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 750px;
  background-image: url("../assets/bg_links.png");
  /* background-attachment: fixed; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  opacity: 0.4;
  z-index: -1;
}

@media (max-width: 768px) {
  .links-bg::before {
    background-size: cover;
    opacity: 0.3;
  }
}
