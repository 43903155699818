.teacher-info-container {
  width: 90%;
  /* max-width: 800px; */
  margin: 20px auto 40px auto;
  border: 3px solid goldenrod;
  padding: 20px;
  border-radius: 1.4rem;
}

.teacher-info-divider {
  border: none;
  height: 2px;
  background-color: goldenrod;
  width: 80%;
  margin: 0px auto 40px auto;
}

.teacher-info-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 10px;
  color: #c1911a;
  margin-top: 5px;
}

.teacher-info-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}

.teacher-info-image {
  width: 350px;
  height: auto;
  border-radius: 8px;
}

.teacher-info-description {
  flex: 1;
  text-align: justify;
  font-size: 1rem;
}

.teacher-info-description-full {
  margin-top: 16px;
  text-align: justify;
}

@media (max-width: 600px) {
  .teacher-info-container {
    border-radius: 1rem;
    margin: 20px auto;
  }
  .teacher-info-content {
    flex-direction: column;
    align-items: center;
  }

  .teacher-info-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 16px;
  }

  .teacher-info-description,
  .teacher-info-description-full {
    text-align: justify;
  }
}
