/* Gallery.css */
.gallery-bg {
  position: relative;
  height: 100%;
  z-index: 0;
}

.gallery-bg::before {
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 750px;
  background-image: url("../assets/bg_links.png");
  /* background-attachment: fixed; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  opacity: 0.2;
  z-index: -1;
}

.carousel-dialog-content {
  background-color: rgba(255, 249, 235, 0.5);
}

.collection-title {
  color: rgb(193, 145, 26);
}

.collection-description {
  color: #515151;
}

.collection-date {
  color: rgb(193, 145, 26);
}

.carousel-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px; /* Adjust the height as needed */
}

.carousel-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .carousel-image-container {
    height: fit-content; /* Adjust the height as needed */
  }
  .gallery-bg::before {
    background-size: cover;
  }
}
