.mission-header {
  font-size: 2rem;
  padding: 10px;
  text-align: center;
  color: goldenrod;
  margin-top: 30px;
}

.mission-paragraph {
  font-size: 1.2rem;
  padding: 10px;
  width: 70%;
  margin: auto;
  color: rgb(129, 97, 18);
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .mission-header {
    font-size: 1.8rem;
    padding: 10px;
    text-align: center;
  }

  .mission-paragraph {
    font-size: 1.2rem;
    padding: 20px;
    width: 90%;
    text-align: justify;
  }
}
