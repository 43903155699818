.footer {
  background-color: #0f0f0f;
  color: #fff;
  padding: 30px 30px 10px 30px;
  z-index: 1;
  opacity: 0.9;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-info {
  flex: 1;
  margin-right: 10px;
  min-width: 250px;
}

.map-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 250px;
  padding-right: 50px;
}

.map-wrapper {
  width: 60%;
  height: 225px;
}

.social-media {
  margin-top: 40px;
}

.social-link {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
  font-size: 2rem;
}

.social-link:hover {
  color: goldenrod;
}

.footer-bottom {
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgb(223, 223, 223);
}

.bottom-p {
  margin: 0.3rem 0 1.5rem 0;
}

.name-link {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.name-link:hover {
  color: goldenrod;
}

.icon-text {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
}

.footer-icon {
  color: goldenrod;
  font-size: 2.5rem;
}

.footer-icon:hover {
  color: #e5e5e5;
}

.contact-text {
  width: 100%;
  text-decoration: none;
  color: rgb(223, 223, 223);
}
@media (max-width: 600px) {
  .container {
    flex-direction: column;
  }

  .contact-info {
    margin-bottom: 20px;
  }

  .map-wrapper {
    width: 80%;
    /* height: 300px; */
  }

  .map-container {
    justify-content: center;
    padding: 20px 0px;
  }

  .icon-text {
    gap: 30px;
  }

  .footer-bottom {
    flex-direction: column;
    font-size: 0.8rem;
  }
}
