.banner {
  position: relative;
  width: 100%;
  height: 65vh;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: cover;
  opacity: 0; /* Start with opacity 0 */
  transform: translateX(-5%);
  transition: transform 1.5s ease-out, opacity 1.5s ease-out;
}

.banner.visible .banner-image {
  opacity: 1; /* End with opacity 1 */
  transform: translateX(0);
}

.banner-text {
  position: relative;
  font-size: 5rem; /* Adjust as needed */
  color: rgb(255, 248, 208);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.7s ease-in 1.5s, transform 0.7s ease-in 1.5s;
}

.text-visible {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .banner-text {
    font-size: 4.5rem;
    text-align: left;
    padding: 0 20px;
    color: #d9b865;
  }

  .banner {
    height: 70vh;
  }

  .banner-image {
    background-position: center left 21%;
  }
}
