.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: 0px;
  padding: 0px 20px 20px 20px;
}

.calendar-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: goldenrod;
}

@media (max-width: 768px) {
  .calendar-container {
    margin-top: 0;
    padding: 10px 5px;
    height: 80vh;
  }
}
