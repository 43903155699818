.ext-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  /* height: 100vh; */
  padding: 30px;
}

.ext-heading {
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 0rem;
  color: rgb(175, 130, 16);
}

.external-link {
  font-size: 1.3rem;
  color: #000;
  text-decoration: none;
}

.external-link:hover {
  color: goldenrod;
}

@media screen and (max-width: 768px) {
  .ext-container {
    padding: 20px 40px 20px 20px;
    text-align: justify;
  }

  .ext-heading {
    font-size: 1.8rem;
  }

  .external-link {
    font-size: 1.1rem;
  }
}
