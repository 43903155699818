/* HomePage.css */

.home-bg {
  position: relative;
  height: 100%;
  z-index: 0;
}

.home-bg::before {
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 750px;
  background-image: url("../assets/bg_home.png");
  background-size: cover;
  opacity: 0.4;
  z-index: -1;
}

.home-page-container {
  z-index: 1;
  position: relative;
  display: inline-block;
  width: 100%;
}

@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInButton {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile and tablet view adjustments */
@media (max-width: 1024px) {
  .home-bg::before {
    height: 550px;
  }
}

@media (max-width: 768px) {
  .home-bg::before {
    right: 0;
    left: inherit;
    height: 600px;
    opacity: 0.2;
  }
}
