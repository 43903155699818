.image-container {
  overflow: hidden; /* Ensure the image doesn't overflow the container */
  background-color: rgba(218, 165, 32, 0.3);
  height: 114vh;
}

.meditating-buddha {
  width: 100%;
  height: 114vh;
  position: relative;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in-image {
  opacity: 1;
}

.fade-out-image {
  opacity: 0;
}

.fade-in-text {
  animation: fadeInText 2.5s ease-in-out forwards 0.2s;
}

.fade-in-button {
  animation: fadeInButton 1s ease-in-out forwards;
}

.learn-more-button {
  margin-top: 60px;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  pointer-events: auto;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
}

.learn-more-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.welcome-overlay {
  position: absolute;
  width: 100%;
  padding-top: 50px;
  top: 0;
  z-index: 2;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-text {
  text-align: center;
  padding-top: 40px;
  font-size: 3.5rem;
  color: rgb(255, 248, 208);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  pointer-events: auto;
  opacity: 0;
}

@media (max-width: 768px) {
  .image-container {
    height: 70vh;
  }

  .meditating-buddha {
    height: 70vh;
    object-fit: cover;
    object-position: center;
  }

  .learn-more-button {
    margin-top: 60px;
    font-size: 1rem;
    padding: 8px 16px;
  }

  .welcome-text {
    padding-top: 20px;
    font-size: 1.8rem;
  }
}
